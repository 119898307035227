import { useState, useEffect } from "react";
import "./App.css";
import Constants from "./Constants";
import Loader from "react-loader-spinner";

const App = () => {
  const BASE_URL = process.env.NODE_ENV === "development" ? "http://localhost:3001" : window.location.origin;
  const [data, setData] = useState([]);
  const [token, setToken] = useState<string>(localStorage.getItem("token") ?? "");
  const [loginFailed, setLoginFailed] = useState<boolean>(false);
  const [PLZCorrect, setPLZCorrect] = useState<boolean>(true);
  const [plz_1_Amount, setPlz_1_Amount] = useState("0");
  const [plz_2_Amount, setPlz_2_Amount] = useState("0");
  const [cashflow_not_assigned, setCashflow_not_assigned] = useState("0");
  const [plz_1, setplz_1] = useState("");
  const [plz_2, setPlz_2] = useState("");
  const [CompanyName, setCompanyName] = useState("");
  const [executeDisabled, setExecuteDisabled] = useState(false);

  useEffect(() => {
    getCompanyName();
  }, []);

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const username = (e.currentTarget[0] as HTMLInputElement).value;
    const password = (e.currentTarget[1] as HTMLInputElement).value;
    const options: RequestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    options.body = Constants.getTokenRequestString(username, password);
    const response = await fetch("https://idsrv.orphy.com/connect/token", options);
    if (response.status >= 400) {
      setLoginFailed(true);
    } else {
      const token = (await response.json()).access_token;
      setLoginFailed(false);
      localStorage.setItem("token", token);
      setToken(token);
      await getCompanyName();
    }
  };

  const getCompanyName = async () => {
    if (token) {
      const meResponse = await fetch("https://api.orphy.com/api/v1/Me", {
        headers: {
          Authorization: `bearer ${token}`,
        },
      });
      const me = await meResponse.json();
      setCompanyName(me.Companies.find((x: any) => x.Id === me.CompanyId).Name);
    }
  };

  const executeAssigner = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setExecuteDisabled(true);
    const plz_1 = (e.currentTarget[0] as HTMLInputElement).value;
    const plz_2 = (e.currentTarget[1] as HTMLInputElement).value;
    const plzCorrect = !!plz_1 && !!plz_2 && plz_1.length === 4 && plz_2.length === 4;
    setPLZCorrect(plzCorrect);
    if (plzCorrect) {
      setplz_1(plz_1);
      setPlz_2(plz_2);
      const response = await fetch(`${BASE_URL}/api/assign_bills`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: localStorage.getItem("token"), plz_1: plz_1, plz_2: plz_2 }),
      });
      if (response.status === 401) {
        localStorage.removeItem("token");
        setToken("");
        setExecuteDisabled(false);
        return;
      }
      const data = await response.json();

      const cashflow_plz_1 = data.filter((x: any) => x.plz === plz_1);
      let amount = 0;
      cashflow_plz_1.forEach((item: any) => {
        amount += item.jobPositions.map((j: any) => j.Amount * j.Price).reduce((a: number, b: number) => a + b, 0);
      });
      setPlz_1_Amount(getFormattedNumber(amount));

      amount = 0;
      const cashflow_plz_2 = data.filter((x: any) => x.plz === plz_2);
      cashflow_plz_2.forEach((item: any) => {
        amount += item.jobPositions.map((j: any) => j.Amount * j.Price).reduce((a: number, b: number) => a + b, 0);
      });
      setPlz_2_Amount(getFormattedNumber(amount));

      amount = 0;
      const cashflow_not_assigned = data.filter((x: any) => !x.assigned);
      cashflow_not_assigned.forEach((item: any) => {
        amount += item.jobPositions.map((j: any) => j.Amount * j.Price).reduce((a: number, b: number) => a + b, 0);
      });
      setCashflow_not_assigned(getFormattedNumber(amount));

      data.sort((a: any) => (a.assigned ? 1 : -1));

      setData(data);
    }
    setExecuteDisabled(false);
  };

  const getFormattedNumber = (amount: number) => {
    return new Intl.NumberFormat("de-CH", { style: "currency", currency: "CHF" }).format(amount);
  };

  return (
    <>
      <div className="app">
        {loginFailed ? (
          <div className="failedMessage">Falscher Benutzername oder Passwort</div>
        ) : (
          <div style={{ visibility: "hidden" }} className="failedMessage">
            Falscher Benutzername oder Passwort
          </div>
        )}
        {token === "" ? (
          <form onSubmit={(e) => handleLogin(e)}>
            <label htmlFor="username">Benutzername</label>
            <input id="username" type="text" placeholder="Benutzername" />
            <label htmlFor="password">Passwort </label>
            <input id="password" type="password" placeholder="Passwort" />
            <button type="submit">Anmelden</button>
          </form>
        ) : (
          <>
            <div>{CompanyName}</div>
            {PLZCorrect ? (
              <div style={{ visibility: "hidden" }} className="failedMessage">
                PLZ müssen ausgefüllt sein
              </div>
            ) : (
              <div className="failedMessage">PLZ müssen ausgefüllt sein</div>
            )}
            {
              <form onSubmit={(e) => executeAssigner(e)}>
                <label htmlFor="standort_1">PLZ Standort 1</label>
                <input id="standort_1" type="number" placeholder="PLZ Standort 1" />
                <label htmlFor="standort_2">PLZ Standort 2</label>
                <input id="standort_2" type="number" placeholder="PLZ Standort 2" />
                <button disabled={executeDisabled} type="submit">
                  Ausführen
                </button>
              </form>
            }
            {executeDisabled ? <div>Die Anfrage wird einige Minuten dauern</div> : <div style={{ visibility: "hidden" }}>Die Anfrage wird einige Minuten dauern</div>}

            <div className="spinner">
              <Loader type="Puff" color="#00BFFF" height={100} width={100} secondaryColor={"rgb(19, 26, 38)"} visible={executeDisabled} />
            </div>
          </>
        )}
        {data.length ? (
          <div className="defaultPadding">
            <div>
              Standort {plz_1}: {plz_1_Amount}
            </div>
            <div style={{ paddingTop: "20px" }}>
              Standort {plz_2}: {plz_2_Amount}
            </div>
            <div style={{ paddingTop: "20px" }}>Nicht zugewiesen: {cashflow_not_assigned}</div>
          </div>
        ) : (
          <div className="defaultPadding" style={{ visibility: "hidden" }}>
            <div>
              Standort {plz_1}: {plz_1_Amount}
            </div>
            <div>
              Standort {plz_2}: {plz_2_Amount}
            </div>
            <div>Nicht zugewiesen: {cashflow_not_assigned}</div>
          </div>
        )}
        <div className="flex">
          {data.map((i: any) => (
            <div className="flexRow" key={i.bill.Id}>
              <div>
                <a href={"https://app.orphy.com/BillDetail/Details/" + i.bill.Id} target="_blank">
                  {i.bill.CustomId}
                </a>
              </div>
              <div>
                <a href={"https://app.orphy.com/PersonDetail/Details/" + i.bill.PersonId} target="_blank">
                  {i.bill.Person}
                </a>
              </div>
              <div>{new Date(i.bill.Date).toLocaleDateString()}</div>
              <div>{i.assigned ? <div>Zugewiesen</div> : <div>Nicht Zugewiesen</div>}</div>
              <div>{getFormattedNumber(i.jobPositions.map((j: any) => j.Amount * j.Price).reduce((a: number, b: number) => a + b, 0))}</div>
              <div>{i.assigned ? "Standort: " + i.plz : <div></div>}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default App;
